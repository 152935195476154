@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Montserrat:wght@600;700&display=swap');

.App {
  font-family: 'Inter', sans-serif;
  background-color: #0A192F;
  color: #E6F1FF;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  filter: drop-shadow(0 0 0.75rem #64FFDA);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #112240;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #64FFDA;
  text-decoration: none;
  transition: all 0.3s ease;
}

.App-link:hover {
  text-shadow: 0 0 10px rgba(100, 255, 218, 0.5);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(100, 255, 218, 0.1);
  border-left-color: #64FFDA;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.glow-box {
  box-shadow: 0 0 10px rgba(100, 255, 218, 0.3);
  transition: all 0.3s ease;
}

.glow-box:hover {
  box-shadow: 0 0 20px rgba(100, 255, 218, 0.5);
}

.glow-text {
  text-shadow: 0 0 10px rgba(100, 255, 218, 0.5);
}

.btn {
  @apply bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary;
  position: relative;
  overflow: hidden;
}

.btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.btn:hover::after {
  animation: ripple 1s ease-out;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  20% {
    transform: scale(25, 25);
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.input {
  @apply bg-background-lighter text-text border border-background-lighter rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-primary;
}

.card {
  @apply bg-background-light p-6 rounded-lg shadow-md;
}

.heading {
  @apply font-display font-semibold text-primary;
}

.text-muted {
  @apply text-text-muted;
}

.animate-glow-pulse {
  animation: glow-pulse 2s ease-in-out infinite;
}

@keyframes glow-pulse {
   0%, 100% { box-shadow: 0 0 10px rgba(100, 255, 218, 0.3); }
  50% { box-shadow: 0 0 20px rgba(100, 255, 218, 0.6); }
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #0A192F;
}

::-webkit-scrollbar-thumb {
  background: #3B82F6;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2563EB;
}
